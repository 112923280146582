import {Script} from "gatsby";
import React from "react";
import {useUserConsent} from "../../utils/UserConsentContext";
import {Helmet} from "react-helmet";

const GoogleTag = () => {

    const {consent} = useUserConsent();

    return <>{consent.marketing && (<>
                <Script  id={"pixel"} dangerouslySetInnerHTML={{
                    __html: `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1888912951583005');
                fbq('track', 'PageView');`}}></Script>
            <Script id={"ads"} src={`https://www.googletagmanager.com/gtag/js?id=AW-11470431428`}></Script>
            <Script dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-11470431428');`}}></Script>
                <Script id={"g"} src={`https://www.googletagmanager.com/gtag/js?id=G-05EK8Q8NWG`}></Script>
                <Script dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-05EK8Q8NWG');`}}></Script>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<img height="1" width="1" style="display:none"
                           src="https://www.facebook.com/tr?id=1888912951583005&ev=PageView&noscript=1"/>`,
                }}
            />
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T6M3T842"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            />
        </>
    )}</>
}

export default GoogleTag
