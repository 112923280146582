import React from 'react'
import PropTypes from 'prop-types'
import {UserConsentProvider} from "../utils/UserConsentContext";
import GoogleTag from "../components/analytics/GoogleTag";
import CookieConsent from "../components/popups/CookieConsent";
import {Script} from "gatsby";

const Layout = ({ children }) => {

    return (<><UserConsentProvider>
        <GoogleTag/>
        <CookieConsent/>
        {children}
    </UserConsentProvider></>)
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
